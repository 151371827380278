<template>
  <div class="card">
    <h3>{{ groupName }}</h3>
    <p>
      <img src="@/assets/icons/users.svg" alt="" class="q-mr-sm" />{{
        quantityStudents
      }}
      participantes
    </p>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref } from "vue";

export default {
  name: "CardGroupsParticipants.vue",
  emits: ["update:quantityParticipants", "update:students"],
  props: {
    nameCourse: String,
    students: {
      required: true,
      type: Array,
    },
    groupName: {
      required: true,
      type: String,
    },
    quantityStudents: Number,
  },
  setup(props, { emit }) {
    const store = new useStore();
    const confirm = ref(false);

    let selected = ref([]);
    let filter = ref("");
    let rows = ref(props.students);
    let columnsTable = ref([
      {
        name: "value",
        align: "left",
        label: "ID",
        field: (row) => row.value,
        sortable: false,
      },
      {
        name: "label",
        required: true,
        label: "Nome",
        align: "left",
        field: (row) => row.label,
        sortable: true,
      },
      {
        name: "email",
        required: true,
        label: "Email/Matrícula",
        align: "left",
        field: (row) => row.email,
        sortable: true,
      },
    ]);

    function removeAllStudentsSelected(studentsSelected) {
      rows.value = rows.value.filter((student) => {
        return !studentsSelected.includes(student);
      });
      selected.value = [];
    }

    function modifyValues() {
      let newQuantity = rows.value.length;

      emit("update:quantityParticipants", newQuantity);
      emit("update:students", rows.value);
    }

    return {
      confirm,
      selected,
      filter,
      columnsTable,
      removeAllStudentsSelected,
      rows,
      modifyValues,
    };
  },
};
</script>

<style scoped>
.card {
  height: 247px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 0;
}

h3 {
  font-family: "Montserrat";
  margin: 0 1rem 1rem 1rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: -0.0313rem;
  color: #666666;
  text-align: center;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #8c8c8c;
  margin-bottom: 2.25rem;
}

.label-btn {
  letter-spacing: -0.25px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.group-name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
</style>
