<template>
  <section>
    <Mural
      :breadcrumbs="breadcrumbs"
      route="group-trail"
      :query="{
        courseId: courseId,
        title: titleCourse,
        isFinished: finishedCourse,
      }"
    >
      <q-btn
        :disable="trail.groups.length < 0 ? true : false"
        color="default-pink"
        rounded
        no-caps
        no-wrap
        label="Salvar grupos"
        @click="AddGroups"
      />
    </Mural>
    <div class="container-participants">
      <div>
        <div class="row items-start">
          <label class="q-mr-sm">Segmentos</label>
          <q-tooltip anchor="top left" self="top left">
            Utilize os segmentos para poder filtrar os grupos
          </q-tooltip>
          <q-img
            src="@/assets/icons/info_cicle.svg"
            width="16px"
            height="16px"
          />
        </div>
        <q-select
          dense
          outlined
          color="default-pink"
          bg-color="grey-3"
          class="col-11"
          id="semgments"
          multiple
          use-chips
          use-input
          emit-value
          map-options
          @filter="filterFnBranches"
          v-model="trail.branches"
          :options="listBranches"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                Não encontrado
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>
      <div class="container-groups">
        <div class="item-groups">
          <label for="">Grupos</label>
          <q-select
            dense
            outlined
            color="default-pink"
            bg-color="grey-3"
            class="col-11"
            multiple
            use-input
            use-chips
            @filter="filterFnGroups"
            map-options
            v-model="trail.groups"
            :options="optionsGroups"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Não encontrado
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>
        <q-checkbox
          left-label
          v-model="allGroups"
          label="Selecionar todos"
          color="default-pink"
          @update:model-value="selectedFilteredGroups"
        />
      </div>
    </div>
    <div class="cards">
      <CardGroupsParticipants
        v-for="({ label }, index) in trail.groups"
        :groupName="label"
        :key="index"
        class="card-item"
      />
    </div>
    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    />
  </section>
</template>

<script>
/* Components */
import Mural from "@/components/Mural.vue";
import CardGroupsParticipants from "@/components/courses/cards/CardGroupsParticipants.vue";

/* vue */
import { ref, reactive, onMounted, watch } from "vue";

/* Router */
import { useRouter, useRoute } from "vue-router";

/* Services */
import GroupServices from "@/services/GroupServices";
import TrailDataServices from "@/services/TrailDataServices";

import { useQuasar } from "quasar";

export default {
  name: "AddGroups",
  components: {
    Mural,
    CardGroupsParticipants,
  },
  setup() {
    /* Constants */
    const _groupServices = new GroupServices();
    const _route = new useRoute();
    const _router = new useRouter();
    const listUsers = ref([]);
    const $q = new useQuasar();
    const _trailDataServices = new TrailDataServices();
    const trail = reactive({
      branches: [],
      groups: [],
    });

    /* Variables */
    let breadcrumbs = ref(["Adicionar grupos"]);
    let finishedCourse = ref(_route.query.isFinished == 1 ? true : false);
    let titleCourse = _route.query.title;
    let loading = ref(false);
    let courseId = _route.query.courseId;
    let listBranches = ref([]);
    let optionListBranches = ref([]);
    let allGroups = ref(false);
    let listGroups = ref([]);
    let optionsGroups = ref([]);

    /* functions */

    function selectedFilteredGroups() {
      if (allGroups.value) {
        const currentSelectedGroups = trail.groups.map((group) => group.value);
        optionsGroups.value.filter((group) => {
          if (!currentSelectedGroups.includes(group.value)) {
            trail.groups.push(group);
          }
        });
      } else {
        trail.groups = null;
        trail.groups = [];
      }
    }

    async function getAllGroups() {
      _groupServices
        .listGroups()
        .then((response) => {
          response.data.map((item) => {
            listGroups.value.push({
              label: item.group_name,
              value: item.id,
              company_id: item.company_id,
              branch_id: item.branch_id,
            });
          });
          optionsGroups.value = listGroups.value;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function getBranches() {
      await _groupServices
        .listBranches()
        .then((response) => {
          response.data.map((item) => {
            if (item.status) {
              listBranches.value.push({
                label: item.name_branch,
                value: item.id,
              });
            }
          });
          optionListBranches.value = listBranches.value;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function filterFnBranches(val, update) {
      if (val === "") {
        update(() => {
          listBranches.value = optionListBranches.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        listBranches.value = optionListBranches.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    function filterFnGroups(val, update) {
      if (val === "") {
        update(() => {
          if (trail.branches.length > 0) {
            optionsGroups.value = listGroups.value.filter((group) => {
              return trail.branches.includes(group.branch_id);
            });
          }
          optionsGroups.value = listGroups.value;
        });
      }

      update(() => {
        const needle = val.toLowerCase();
        optionsGroups.value = optionsGroups.value.filter((v) => {
          if (trail.branches.length > 0) {
            return (
              v.label.toLowerCase().indexOf(needle) > -1 &&
              trail.branches.includes(v.branch_id)
            );
          }
          return v.label.toLowerCase().indexOf(needle) > -1;
        });
      });
    }

    async function AddGroups() {
      const formData = new FormData();

      formData.append("Course_id", courseId);

      trail.groups.forEach((group) => {
        formData.append("Group_id", group.value);
      });

      await _trailDataServices
        .addGroupsInTrail(formData)
        .then((response) => {
          if (response.data) {
            _router.push({
              name: "group-trail",
              query: { courseId: courseId, title: titleCourse },
            });
            _alertOf(`Grupos adicionados ao curso “${titleCourse}”`, "green-9");
          }
        })
        .catch((error) => {
          _alertOf(`Erro ao tentar salvar as alterações`, "red");
          trail.groups = [];
        });
    }

    function _alertOf(message, color = "red-9") {
      $q.notify({
        message: message,
        color: color,
        position: "top",
      });
    }

    /* Lifecycles */
    onMounted(() => {
      getBranches();
      getAllGroups();
    });

    watch(
      () => trail.groups,
      (newGroup, oldGroup) => {
        if (newGroup.length == 0) {
          allGroups.value = false;
        }
      }
    );

    watch(
      () => trail.branches,
      (newBranch, oldBranch) => {
        if (newBranch.length == 0) {
          trail.groups = null;
          trail.groups = [];
          allGroups.value = false;
        }

        if (newBranch.length != oldBranch.length) {
          optionsGroups.value = listGroups.value;
        }

        if (newBranch.length != 0) {
          optionsGroups.value = listGroups.value.filter((group) => {
            return newBranch.includes(group.branch_id);
          });

          let currentSelectedGroups = trail.groups.map((group) => group.value);

          trail.groups = optionsGroups.value.filter((group) => {
            if (currentSelectedGroups.includes(group.value)) {
              return group;
            }
          });
        }
      }
    );

    watch(optionsGroups, (newGroup, oldGroup) => {
      if (allGroups.value && trail.branches.length != 0) {
        if (newGroup.length > oldGroup.length) {
          let currentSelectedGroups = trail.groups.map((group) => group.value);
          trail.groups = optionsGroups.value.filter((group) => {
            if (currentSelectedGroups.includes(group.value)) {
              return group;
            }
          });
        }
      }
    });

    return {
      breadcrumbs,
      finishedCourse,
      listGroups,
      trail,
      filterFnGroups,
      filterFnGroups,
      allGroups,
      loading,
      titleCourse,
      AddGroups,
      selectedFilteredGroups,
      courseId,
      filterFnBranches,
      optionsGroups,
      listBranches,
    };
  },
};
</script>

<style scoped>
section {
  margin: 1rem;
  display: flex;
  flex-direction: column;
}
label {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.45;
}

.container-groups {
  display: flex;
  flex-wrap: nowrap;
}

.item-groups {
  width: calc(80% - 25px);
  margin-right: 25px;
}

.container-participants {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.container-participants > div {
  margin-bottom: 40px;
}

.cards {
  overflow-y: scroll;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 28px;
}

.card-item {
  width: 100%;
}

@media (min-width: 700px) {
  .cards {
    flex-wrap: wrap;
    flex-direction: row;
  }

  .card-item {
    min-width: 210px;
    flex: calc(25% - 1.75rem);
    max-width: calc(25% - 1.75rem);
  }
}
</style>
